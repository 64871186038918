import React from 'react'
import '../../App.css'
import FlavourPage from '../FlavourPage'

function Flavours() {
    return (
        <>
            <FlavourPage />
        </>
    )
}

export default Flavours