import React from 'react'
import '../../App.css'
import ContactHero from "../ContactHero"

function Contact() {
    return (
        <>
            <ContactHero />
        </>
    )
}

export default Contact