import React from 'react'
import '../../App.css'
import ServicesHero from "../ServicesHero"

function Services() {
    return (
        <>
            <ServicesHero />
        </>
    )
}

export default Services