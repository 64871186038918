import React from 'react'
import '../../App.css'
import NavHero2 from '../NavHero2'
import FAQhero from "../FAQhero"

function FAQ() {
    return (
        <>
            <NavHero2 />
            <FAQhero />
        </>
    )
}

export default FAQ