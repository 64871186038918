export default [
    {
        id: 3,
        title: "Banana Tub",
        description: "If you love banana, you'll definitely love this flavour! It tastes exactly like banana with a mixture of sugar.",
        coverImg: "images/SP_Banana_TR.jpg",
        preview1: "images/SP_Banana_preview1.JPG",
        preview2: "images/SP_Banana_preview2.JPG",
        price: "$4.50"
    },
    {
        id: 4,
        title: "Bubblegum Tub",
        description: "Our sweet tooth favourite! Let our bubble gum cotton candy melt on your tongue with an unforgettable taste.",
        coverImg: "images/SP_Bubblegum_TR.jpg",
        preview1: "images/SP_Bubblegum_preview1.JPG",
        preview2: "images/SP_Bubblegum_preview2.JPG",
        price: "$4.50"
    },
    {
        id: 5,
        title: "Cherry Tub",
        description: "A mix of sweet and sour! If you give this flavour a try, you'll never choose another flavour again.",
        coverImg: "images/SP_Cherry_TR.jpg",
        preview1: "images/SP_Cherry_preview1.JPG",
        preview2: "images/SP_Cherry_preview2.JPG",
        price: "$4.50"
    },
    {
        id: 6,
        title: "Grape Tub",
        description: "A sweet flavour that resembles the fruit.",
        coverImg: "images/SP_Grape_TR.jpg",
        preview1: "images/SP_Grape_preview1.JPG",
        preview2: "images/SP_Grape_preview2.JPG",
        price: "$4.50"
    },
    {
        id: 7,
        title: "Green Apple Tub",
        description: "The acidity of green apple will make you feel refreshed!",
        coverImg: "images/SP_Green Apple_TR.jpg",
        preview1: "images/SP_Green Apple_preview1.JPG",
        preview2: "images/SP_Green Apple_preview2.JPG",
        price: "$4.50"
    },
    {
        id: 8,
        title: "Green Watermelon Tub",
        description: "A sweet juicy flavour that melts in your mouth.",
        coverImg: "images/SP_Green Watermelon_TR.jpg",
        preview1: "images/SP_Green Watermelon_preview1.JPG",
        preview2: "images/SP_Green Watermelon_preview2.JPG",
        price: "$4.50"
    },
    {
        id: 9,
        title: "Orange Tub",
        description: "A sour flavour with a sweet aftertaste.",
        coverImg: "images/SP_Orange_TR.jpg",
        preview1: "images/SP_Orange_preview1.JPG",
        preview2: "images/SP_Orange_preview2.JPG",
        price: "$4.50"
    },
    {
        id: 10,
        title: "Pina Colada Tub",
        description: "Enjoy an alcohol-free pina colada in any season.",
        coverImg: "images/SP_Pina Colada_TR.jpg",
        preview1: "images/SP_Pina Colada_preview1.JPG",
        preview2: "images/SP_Pina Colada_preview2.JPG",
        price: "$4.50"
    },
    {
        id: 11,
        title: "Strawberry Tub",
        description: "A sweet and fresh strawberry taste with a hint of acidity.",
        coverImg: "images/SP_Strawberry_TR.jpg",
        preview1: "images/SP_Strawberry_preview1.JPG",
        preview2: "images/SP_Strawberry_preview2.JPG",
        price: "$4.50"
    },
]