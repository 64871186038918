import React from 'react'
import '../../App.css'
import EventsHero from '../EventsHero'

function Events() {
    return (
        <>
            <EventsHero />
        </>
    )
}

export default Events