import React from 'react'
import '../../App.css'
import AboutHero from '../AboutHero'

function About() {
    return (
        <>
            <AboutHero />
        </>
    )
}

export default About
