export default [
    {
        id: 1,
        image: "hero-images/Copy of JacobAres1stBday_02182023-1.jpg",
    },
    {
        id: 2,
        image: "hero-images/Copy of JacobAres1stBday_02182023-2.jpg",
    },
    {
        id: 3,
        image: "hero-images/Copy of JacobAres1stBday_02182023-3.jpg",
    },
    {
        id: 4,
        image: "hero-images/Copy of JacobAres1stBday_02182023-4.jpg",
    },
    {
        id: 5,
        image: "hero-images/Copy of CanaComia1stBday_02192023-1.jpg",
    },
    {
        id: 6,
        image: "hero-images/Copy of CanaComia1stBday_02192023-2.jpg",
    },
    {
        id: 7,
        image: "hero-images/Copy of CanaComia1stBday_02192023-3.jpg",
    },
    {
        id: 8,
        image: "hero-images/Copy of CanaComia1stBday_02192023-4.jpg",
    },
    {
        id: 9,
        image: "hero-images/Copy of SonicHHog7thBday_04222023-1.jpg",
    },
    {
        id: 10,
        image: "hero-images/Copy of SonicHHog7thBday_04222023-2.jpg",
    },
    {
        id: 11,
        image: "hero-images/Copy of SonicHHog7thBday_04222023-3.jpg",
    },
    {
        id: 12,
        image: "hero-images/Copy of SonicHHog7thBday_04222023-4.jpg",
    },
    {
        id: 13,
        image: "hero-images/AkiroJaive1stBday-1.jpg",
    },
    {
        id: 14,
        image: "hero-images/Copy of AkiroJaive1stBday_05212023-2.jpg",
    },
    {
        id: 15,
        image: "hero-images/Copy of AkiroJaive1stBday_05212023-3.jpg",
    },
    {
        id: 16,
        image: "hero-images/Copy of AkiroJaive1stBday_05212023-4.jpg",
    }
]